import React from 'react';
import {
    Typography, Box, Grid,
} from '@mui/material';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../../layout/Layout';
import ServiceIntroduction from '../../components/services/ServiceIntroduction';
import HomeConsultation from '../../components/home/HomeConsultation';
import FunctionalityAndCasesContainer from '../../components/services/FunctionalityAndCasesContainer';
import colors from '../../theme/colors';

const infoWrapper = {
    maxWidth: '1440px',
    width: '100%',
    padding: '41px 136px 40px',
    minHeight: '195px',
};
const customTextTypography = {
    fontFamily: 'Nunito',
    fontSize: '22px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '39px',
    letterSpacing: '0em',
    textAlign: 'left',
};

const certificatedPartnerWrapper = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
};

const certificatedPartnerContainer = {
    padding: '31px 18px 39px 40px',
    minHeight: '212px',
    width: '294px',
    background: `linear-gradient(180deg, #26192C -31.93%, ${colors.violet} 54.16%)`,
    border: '2px solid transparent',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
};

const platformsWrapper = {
    maxWidth: '1440px',
    width: '100%',
    background: colors.darkViolet,
    minHeight: '252px',
    padding: '51px 136px 36px',
};

const serviceIntroductionData = {
    title: 'Cloud PBX',
    firstParagraph: 'Central telefónica VoIP en la nube con usuarios ilimitados.',
    secondParagraph: 'Modernizá la comunicación de tu negocio y mantené a tu equipo siempre en línea.',
    gatsbyImg: (<StaticImage src="../../images/cloud_pbx.svg" alt="Cloud PBX" />),
    isButton: true,
    buttonText: 'Contactanos',
    buttonLinkTo: '/contact_us',
};

const casesOfUseData = {
    title: 'Datos de la solución',
    cases: [
        {
            image: (<StaticImage src="../../images/3cx_icon.svg" alt="3CX" />),
            text: 'Sistema de telefonía 3CX',
        },
        {
            image: (<StaticImage src="../../images/chat_voicechat.svg" alt="Chat y videoconferencia" />),
            text: 'Solución de chat y videoconferencia',
        },
        {
            image: (<StaticImage src="../../images/phone.svg" alt="Llamada" />),
            text: 'Licencia por cantidad de llamadas simultáneas',
        },
    ],
    gridColumns: 12,
    maxRowGridSize: 4,
    customPadding: '39px 136px 78px',
};

const availablePlatforms = [
    {
        image: (<StaticImage src="../../images/chrome_icon.svg" alt="Chrome" />),
        key: 1,
    },
    {
        image: (<StaticImage src="../../images/windows3cx_icon.svg" alt="Windows" />),
        key: 2,
    },
    {
        image: (<StaticImage src="../../images/googleplay_icon.svg" alt="Google Play" />),
        key: 3,
    },
    {
        image: (<StaticImage src="../../images/appstore_icon.svg" alt="Appstore" />),
        key: 4,
    },
];
const CloudPBX = () => (
    <Layout>
        <ServiceIntroduction serviceIntroductionData={serviceIntroductionData} />
        <FunctionalityAndCasesContainer casesOfUseData={casesOfUseData} />
        <Box
            style={{
                display: 'flex',
                justifyContent: 'center',
                background: colors.burgundy,
                boxShadow: 'inset 0px -8px 20px rgba(0, 0, 0, 0.15)',
            }}
        >
            <Grid container sx={(theme) => ({ [theme.breakpoints.down('sm')]: { padding: '48px 24px' }, ...infoWrapper })}>
                <Grid item md={8} sm={12} sx={(theme) => ({ [theme.breakpoints.down('md')]: { paddingTop: '0px', marginBottom: '24px' }, paddingTop: '32px' })}>
                    <Typography variant="h4" sx={{ ...customTextTypography, fontWeight: 700 }} mb={1}>
                        ¿Aplica para mi caso?
                    </Typography>
                    <Typography variant="h5" style={{ lineHeight: '32px', paddingRight: '10px' }}>
                        Independientemente de la solución de telefonía que tengas en tu oficina o sucursales, líneas analógicas, digitales o tramas E1, contamos con la solución necesaria para convertirlas en SIP y así poder registrarlas en nuestra central en la nube.
                    </Typography>
                </Grid>
                <Grid item md={4} sm={12} sx={{ ...certificatedPartnerWrapper }}>
                    <Box sx={{ ...certificatedPartnerContainer }}>
                        <Typography variant="h5" mb={2} style={{ fontWeight: 700 }}>
                            Partner Certificado de 3CX
                        </Typography>
                        <Box>
                            <StaticImage src="../../images/3cx_certifiedengineer.svg" alt="3CX Certificación" />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>

        <Box
            style={{
                display: 'flex',
                justifyContent: 'center',
                background: colors.darkViolet,
            }}
        >
            <Box sx={(theme) => (
                {
                    [theme.breakpoints.down('lg')]: { padding: '51px 136px 14px' },
                    [theme.breakpoints.down('sm')]: { padding: '48px 24px 0px' },
                    ...platformsWrapper,
                }
            )}
            >
                <Typography variant="subSectionH2">
                    Disponible para múltiples dispositivos
                </Typography>
                <Grid container mt={7}>
                    {availablePlatforms.map((platform) => (
                        <Grid
                            item
                            lg={3}
                            xs={12}
                            md={6}
                            key={platform.key}
                            sx={(theme) => ({ [theme.breakpoints.down('lg')]: { paddingBottom: '24px' } })}
                        >
                            <Box>
                                {platform.image}
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Box>
        <HomeConsultation />
    </Layout>
);

export default CloudPBX;
